<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="recharge-top">
      <div class="label">{{i18n.t('recharge.label1')}}($)</div>
      <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
      <div class="tabs">
        <div class=" flex-center-between tab">
          <div class="tab-label">{{i18n.t('recharge.label2')}}($)</div>
          <div class="count">${{totalAmountWait}}</div>
        </div>
        <div class="tab flex-center-between">
          <div class="tab-label">{{i18n.t('recharge.label3')}}($)</div>
          <div class="count">${{totalAmount}}</div>
        </div>
      </div>
    </div>

    <div class="sub-title">{{i18n.t('recharge.label4')}}</div>

    <div class="recharge-form clear">
      <div class="input">
        <input type="number" v-model="form.number" :placeholder="i18n.t('recharge.placeholder')">
        <div class="btn">
          <Button @click="submit">{{i18n.t('recharge.btn')}}</Button>
        </div>
      </div>
      <div class="fast-list flex-wrap">
        <div class="list" :class="activeIndex == i ? 'active' : ''" v-for="(item, i) in fastList" :key="i" @click="setMoney(item, i)">{{item}}</div>
      </div>
    </div>

  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import { rechargeStatistics, doRecharge, userInfo,ListPayManage,getCompanyBank } from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      form: {
        number: '',
        type: 1,
      },
      activeIndex: -1,
      tabIndex: 0,
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
      usdt_no: require('@/assets/imgs/usdt_no.png'),
      usdt_select: require('@/assets/imgs/usdt_select.png'),
      bank_no: require('@/assets/imgs/bank_no.svg'),
      bank_select: require('@/assets/imgs/bank_select.svg'),
      userMoney: 0,
      fastList: [50, 100, 200, 300, 500, 600, 800, 1000, 2000, 5000, 8000, 10000],
      address: '',
      totalAmount: 0,
      RechargeInfo: [],
      BankInfo: undefined,
      BankList: undefined,
      totalAmountWait: 0,
      exchange_result: 0,
    }
  },
  computed: {
    show_collection() {
      return this.$store.state.show_collection
    },
    exchange_rate() {
      return this.$store.state.exchange_rate
    },
  },
  watch: {
    'form.number': function (val) {
      if (this.exchange_rate > 0) {
        this.exchange_result = (val * this.exchange_rate).toFixed(2)
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    changeTag(i) {
      this.tabIndex = i
    },
    init() {
      rechargeStatistics().then(res => {
        this.totalAmount = res.data.TotalAmount
        this.totalAmountWait = res.data.TotalAmountWait
      })

      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Balance
      })
      ListPayManage().then(res => {
        this.RechargeInfo = res.data.list
        // 存入缓存
        window.localStorage.setItem('RechargeInfo', JSON.stringify(res.data.list))
      })
      getCompanyBank().then(res => {
        console.log(res.data)
        this.BankList = res.data.Items
        if(res.data.Items.length > 0 ) {
          this.BankInfo = res.data.Items[0]
        }
        // 存入缓存
        window.localStorage.setItem('BankInfo', JSON.stringify(this.BankList))
      })
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money, i) {
      this.form.number =  money
      this.activeIndex = i
    },
    goToRecharge(){
      doRecharge({
        amount: this.form.number + '',
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('recharge.success'))
          this.$router.push({
            name: 'kefu'
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    submit() {
      if (this.tabIndex == 0) {
        if (!this.form.number) {
          this.$toast({
            message: this.i18n.t('recharge.placeholder'),
            icon: 'warning-o'
          })
          return
        }
        if(this.show_collection === 1) {
          window.localStorage.setItem("amount",this.form.number)
          window.localStorage.setItem("type",this.form.type)
          this.$router.push({
            name: 'rechargeDetail',
            query: {
              amount: this.form.number,
              type: this.form.type,
            }
          })
        } else {
          this.goToRecharge()
        }
      } else if (this.tabIndex == 1) {
        this.$router.push({
          name: 'bankRecharge'
        })
      } else if (this.tabIndex == 2) {
        this.$router.push({
          name: 'usdtRecharge'
        })
      }
    }
  }
}
</script>